<template>
    <div class="find-pass">
        <bread-nav></bread-nav>
        <h2>忘记密码</h2>
        <div class="form">
            <div class="form-box">
                <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label-width="0px" prop="mobile">
                        <el-input v-model="formData.mobile" placeholder="请输入注册的手机号码" >
                            <template slot="prepend"
                                ><div class="input-icon">
                                    <img src="~img/alibaba/phone-base.png" /></div
                            ></template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label-width="0px" prop="dynacode">
                        <el-input v-model="formData.dynacode" placeholder="请输入短信动态码" readOnly onfocus="this.removeAttribute('readonly');" onblur="this.setAttribute('readonly',true);">
                            <template slot="append"
                                ><el-button :disabled="dynacodeData.seconds != 120" @click="sendMobileCode('ruleForm')">{{ dynacodeData.codeText }}</el-button></template
                            >
                        </el-input>
                    </el-form-item>
                    <el-form-item label-width="0px" prop="password">
                        <el-input v-model="formData.pass" placeholder="请输入密码" show-password>
                            <template slot="prepend"
                                ><div class="input-icon">
                                    <img src="~img/alibaba/lock-base.png" /></div
                            ></template>
                        </el-input>
                        <el-form-item label="" class="password-strength">
                            <password-strength v-model="formData.pass"></password-strength>
                        </el-form-item>
                    </el-form-item>
                    <el-form-item label-width="0px" prop="checkPass">
                        <el-input v-model="formData.repass" placeholder="请再输入密码" show-password>
                            <template slot="prepend"
                                ><div class="input-icon">
                                    <img src="~img/alibaba/lock-base.png" /></div
                            ></template>
                        </el-input>
                        <el-form-item label="" class="password-strength">
                            <password-strength v-model="formData.repass"></password-strength>
                        </el-form-item>
                    </el-form-item>
                    <el-button type="primary" @click="resetPass('ruleForm')">确认修改</el-button>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import find from "./find.js"
import PasswordStrength from "@/components/PasswordStrength.vue"

export default {
    name: "find_pass",
    mixins: [find],
    components: { PasswordStrength },
    date() {
        return {
            password: ""
        }
    }
}
</script>
<style lang="scss" scoped>
.find-pass {
    padding-top: 30px;
}
h2 {
    width: 625px;
    font-size: 24px;
    color: $ns-text-color-black;
    padding: 10px 0;
    border-bottom: 1px solid $base-color-gray;
    text-align: center;
    margin: 50px auto 0;
    position: relative;

    &:after {
        width: 80px;
        height: 1px;
        background-color: $base-color;
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: 0px;
    }
}

.form {
    width: 500px;
    margin: 30px auto 100px;

    .el-form-item {
        font-size: $ns-font-size-lg;

        .input-icon {
            width: 28px;
            height: 28px;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .el-button {
            background-color: $base-color;
            color: #ffffff;
            border-radius: 0;
        }
    }

    .el-button--primary {
        background-color: $base-color;
        color: #ffffff;
        border-radius: 0;
        width: 100%;
        font-size: 20px;
    }
}
</style>
